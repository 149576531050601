<template>
	<div id="approvalProcess">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">流程审批</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == MeunIndex ? 'active' : ''" v-for="(item, index) in Meun" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="head u-f-item">
				<span class="span">选择条件</span>
				<!-- <el-select size="small" v-model="condition" placeholder="请选择" style="width: 150px;" @change="changeSchool">
					<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select> -->
				<el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="semester" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeSemester">
					<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="content u-f">
				<div class="content-left">
					<div class="u-f-item meun-item" :class="meunLeftIndex == 0 ? 'meun-active' : ''" @click="meunLeftTap(0)">
						<div class="iconfont icon-fabu"></div>
						<div>我的申请</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 1 ? 'meun-active' : ''" @click="meunLeftTap(1)">
						<div class="iconfont icon-caogao"></div>
						<div>抄送给我</div>
					</div>
					<div class="font_blod font_16 p-t-b">审核</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 2 ? 'meun-active' : ''" @click="meunLeftTap(2)">
						<div class="iconfont icon-yishenhe"></div>
						<div>未审核</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 3 ? 'meun-active' : ''" @click="meunLeftTap(3)">
						<div class="iconfont icon-yishenhe1"></div>
						<div>已审核</div>
					</div>
				</div>
				<!-- 我的申请 -->
				<div class="content-right" v-if="meunLeftIndex==0">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
							<!-- <span class="btn" @click="selectAll">选择全部</span> -->
						</div>
						<div class="u-f-item">
							<!-- <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;" @change="sousuoList"> -->
								<!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
							<!-- </el-input> -->
							<el-button size="small" @click="activityVisible=true">请假申请</el-button>
						</div>
					</div>
					<el-table
						:data="Mylist"
						height="90%"
						ref="multipleTable"
						tooltip-effect="dark"
						@selection-change="handleSelectionChange"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="explain" label="请假原因" align="center"></el-table-column>
						<el-table-column prop="start_time_text" label="开始时间" align="center"></el-table-column>
						<el-table-column prop="end_time_text" label="结束时间" align="center"></el-table-column>
						<el-table-column prop="main_department_name" label="审批部门" align="center"></el-table-column>
						<el-table-column prop="audit" label="审批人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item,index) in scope.row.audit">{{item.audit_username}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="ectype_department_name" label="抄送部门" align="center"></el-table-column>
						<el-table-column prop="ectype" label="抄送人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item,index) in scope.row.ectype">{{item.audit_username}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status==1">待审核</el-tag>
								<el-tag v-if="scope.row.status==2">已通过</el-tag>
								<el-tag v-if="scope.row.status==3">未通过</el-tag>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<div class="u-f-item">
							<el-button type="danger" plain size="mini" @click="delActivit">删除</el-button>
						</div>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
				<!-- 我的抄送 -->
				<div class="content-right" v-if="meunLeftIndex != 0">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
							<!-- <span class="btn" @click="selectAll">选择全部</span> -->
						</div>
						<div class="u-f-item">
							<!-- <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;" @change="sousuoList"> -->
								<!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
							<!-- </el-input> -->
							<!-- <el-button size="small" @click="activityVisible=true">请假申请</el-button> -->
						</div>
					</div>
					<el-table
						:data="copyToList"
						height="90%"
						style="width: 100%;border: 1px solid #EEEEEE;"
					>
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="explain" label="请假原因" align="center"></el-table-column>
						<el-table-column prop="start_time_text" label="开始时间" align="center"></el-table-column>
						<el-table-column prop="end_time_text" label="结束时间" align="center"></el-table-column>
						<el-table-column prop="main_department_name" label="审批部门" align="center"></el-table-column>
						<el-table-column prop="audit" label="审批人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item,index) in scope.row.audit">{{item.audit_username}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="ectype_department_name" label="抄送部门" align="center"></el-table-column>
						<el-table-column prop="ectype" label="抄送人员" align="center">
							<template slot-scope="scope">
								<span v-for="(item,index) in scope.row.ectype">{{item.audit_username}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status==1">待审核</el-tag>
								<el-tag v-if="scope.row.status==2">已通过</el-tag>
								<el-tag v-if="scope.row.status==3">未通过</el-tag>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<div class="u-f-item">
							<el-button type="danger" plain size="mini" @click="delActivit">删除</el-button>
						</div>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>

			<el-dialog title="请假申请" :visible.sync="activityVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							请假标题
						</div>
						<el-input v-model="title" placeholder="请输入请假标题" style="width: 70%;"></el-input>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>开始时间</div>
						<el-date-picker value-format="yyyy-MM-dd H:mm" v-model="start_time" type="datetime" placeholder="选择日期"></el-date-picker>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>结束时间</div>
						<el-date-picker value-format="yyyy-MM-dd H:mm" v-model="end_time" type="datetime" placeholder="选择日期"></el-date-picker>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>请假天数</div>
						<el-input v-model="day" placeholder="请输入请假天数" style="width: 70%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal"><span>*</span>请假类型</div>
						<el-radio-group v-model="type" style="width: 80%">
							<el-radio :label="1" style="margin-bottom: 10px;">事假</el-radio>
							<el-radio :label="2" style="margin-bottom: 10px;">病假</el-radio>
							<el-radio :label="3" style="margin-bottom: 10px;">婚假</el-radio>
							<el-radio :label="7" style="margin-bottom: 10px;">丧假</el-radio>
							<el-radio :label="4" style="margin-bottom: 10px;">产假</el-radio>
							<el-radio :label="5" style="margin-bottom: 10px;">陪产假</el-radio>
							<el-radio :label="6" style="margin-bottom: 10px;">哺乳假</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="margin-top: 10px;">
							<span>*</span>
							请假原因
						</div>
						<el-input v-model="explain" type="textarea" :rows="3" placeholder="请输入请假原因" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>审批部门</div>
						<el-select  v-model="main_department_id" placeholder="请选择" style="width: 70%;" @change="mainChanage">
							<el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>抄送部门</div>
						<el-select  v-model="ectype_department_id" placeholder="请选择" style="width: 70%;" @change="ectypeChanage">
							<el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>审批人</div>
						<el-select multiple v-model="audit" placeholder="请选择" style="width: 70%;">
							<el-option v-for="item in peopleList" :key="item.id" :label="item.username" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>抄送人</div>
						<el-select multiple v-model="ectype" placeholder="请选择" style="width: 70%;">
							<el-option v-for="item in peopleList2" :key="item.id" :label="item.username" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeActivity" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit()">确 定</el-button>
				</span>
			</el-dialog>
			
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			Meun: [{ name: '活动申请' }, { name: '请假申请' }, { name: '安全隐患' }],
			MeunIndex: 1,
			meunLeftIndex: 0,
			condition: '',
			conditionList: [],
			year: '',
			yearList: [],
			semester: '',
			semesterList: [],
			total: 0,
			activityVisible: false,
			title:'',
			start_time:'',
			end_time:'',
			explain:'',
			main_department_id:'',
			ectype_department_id:'',
			audit:[],
			ectype:[],
			sectionList:[],
			peopleList:[],
			peopleList2:[],
			page:1,
			limit:10,
			Mylist:[],
			ids:[],
			day:'',
			type:1,
			copyToList:[]
		};
	},
	mounted() {
		this.getYearList();
		this.getSectionList()
	},
	methods: {
		handleSelectionChange(){
			
		},
		// 关闭弹窗
		closeActivity(){
			this.reset()
			this.activityVisible = false;
		},
		handleClose(done){
			this.reset()
			done()
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.myActivitList();
		},
		// 选中
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		// 数据重置
		reset(){
			this.title = '';
			this.start_time = '';
			this.end_time = '';
			this.explain = '';
			this.main_department_id = '';
			this.ectype_department_id = '';
			this.audit = [];
			this.ectype = [];
			this.day = '';
			this.type = 1;
		},
		// 获取部门列表
		getSectionList(){
			this.$api.setting.sectionList({}).then(res=>{
				if(res.data.code==1){
					this.sectionList = res.data.data.rows;
				}
			})
		},
		twacherTap(index) {
			this.MeunIndex = index;
			if(index==0){
				this.$router.push('/approvalProcess')
			}else if(index == 2){
				this.$router.push('/safety')
			}
		},
		meunLeftTap(index) {
			this.meunLeftIndex = index;
			if(index==1){
				this.getLeaveMyProcess(2)
			}else if(index==2){
				this.getLeaveMyProcess(1,1)
			}else if(index==3){
				this.getLeaveMyProcess(1,2)
			}
		},
		// 校区列表
		// schoolCampusIndex() {
		// 	this.$api.setting.schoolCampusIndex().then(res => {
		// 		if (res.data.code == 1) {
		// 			this.conditionList = res.data.data.rows;
		// 			this.condition = this.conditionList[0].id;
		// 			this.getYearList();
		// 			this.getSectionList()
		// 		}
		// 	});
		// },
		// changeSchool(e) {
		// 	this.condition = e;
		// 	this.getYearList();
		// 	this.getSectionList()
		// },
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.yearList = res.data.data;
					if (res.data.data.length) {
						if (this.yearList.filter(item => item.is_default == 2).length > 0) {
							this.year = this.yearList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.year = this.yearList[0].id;
						}
					} else {
						this.year = '';
					}
					this.getSemesterList();
				}
			});
		},
		changeYear(e) {
			this.year = e;
			this.getSemesterList();
		},
		// 学期列表
		getSemesterList() {
			this.$api.setting.getXueQiList({ year_id: this.year }).then(res => {
				if (res.data.code == 1) {
					this.semesterList = res.data.data;
					if (res.data.data.length) {
						if (this.semesterList.filter(item => item.is_default == 2).length > 0) {
							this.semester = this.semesterList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.semester = this.semesterList[0].id;
						}
					} else {
						this.semester = '';
					}
					this.page = 1;
					this.myActivitList()
				}
			});
		},
		changeSemester(e) {
			this.semester = e;
			this.page = 1;
			this.myActivitList()
		},
		mainChanage(e){
			this.userList(e)
		},
		ectypeChanage(e){
			this.userList2(e)
		},
		// 人员列表
		userList(id) {
			let data = {
				// school_campus_id: this.condition,
				school_department_id:id
			};
			this.$api.setting.userList({
				filter: JSON.stringify(data)
			}).then(res => {
				if (res.data.code == 1) {
					this.peopleList = res.data.data.rows;
				}
			});
		},
		// 人员列表
		userList2(id) {
			let data = {
				// school_campus_id: this.condition,
				school_department_id:id
			};
			this.$api.setting.userList({
				filter: JSON.stringify(data)
			}).then(res => {
				if (res.data.code == 1) {
					this.peopleList2 = res.data.data.rows;
				}
			});
		},
		// 请假申请
		submit(){
			let data = {
				// school_campus_id:this.condition,
				year_id:this.year,
				semester_id:this.semester,
				// title:this.title,
				start_time:this.start_time,
				end_time:this.end_time,
				explain:this.explain,
				main_department_id:this.main_department_id,
				ectype_department_id:this.ectype_department_id,
				audit:this.audit,
				ectype:this.ectype,
				day:this.day,
				type:this.type
			}
			this.$api.rule.addLeave(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('提交成功')
					this.myActivitList();
					this.closeActivity()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 获取我的请假申请列表
		myActivitList(){
			let data = {
				// campus_id: this.condition, //11 	string 	是 	校区ID
				year_id:this.year,
				semester_id: this.semester, // 	2 	string 	是 	学年ID
				page: this.page, // 	1 	string 	是 	分页
				limit: this.limit
			}
			this.$api.rule.myLeaveList(data).then(res=>{
				if(res.data.code==1){
					this.Mylist = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		// 删除请假申请
		delActivit(){
			let _this = this;
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					this.$api.rule
						.delLeave({
							id: this.ids
						})
						.then(res => {
							if (res.data.code == 1) {
								this.$message.success('删除成功');
								this.myActivitList();
							} else {
								this.$message.error(res.data.msg);
							}
						});
				});
			} else {
				this.$message.error('请选择要删除的数据');
			}
		},
		// 获取抄送、审核列表
		getLeaveMyProcess(type,status){
			let data = {
				type:type
			}
			if(status){data.status = status}
			this.$api.rule.getLeaveMyProcess(data).then(res=>{
				if(res.data.code==1){
					this.copyToList = res.data.data.rows;
					this.total = res.data.data.total;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		}	
	}
};
</script>

<style lang="scss">
#approvalProcess {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.content {
		background-color: #ffffff;
		height: 74vh;
		border-top: 1px solid #eeeeee;
		.content-left {
			width: 20%;
			border-right: 1px dashed #eeeeee;
			height: 68vh;
			padding: 30px 20px 30px 30px;
			.meun-item {
				cursor: pointer;
				padding: 10px 20px;
				color: #96a2aa;
				.iconfont {
					margin-right: 10px;
				}
			}
			.meun-active {
				background-color: #ddeeff;
				color: #3f9ffe;
			}
		}
		.content-right {
			width: 80%;
			height: 68vh;
			padding: 15px 20px;
			.title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f5f5f5;
					padding: 5px;
				}
				.btn:hover {
					color: #4998ff;
					background: #e4f1ff;
					cursor: pointer;
				}
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		height: 600px;
		overflow-y: auto;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
